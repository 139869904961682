<template>
  <footer v-if="footerConfig && show">
    <div class="wrapper">
      <div class="footer-left">
        <div class="contact">
          <h5>{{ footerConfig.contact_headline }}</h5>
          <a class="tel" :href="'tel:' + footerConfig.contact_phone">{{
            footerConfig.contact_phone
          }}</a>
          <primary-button @click="openMail(footerConfig.contact_button.url)">{{
            footerConfig.contact_button.title
          }}</primary-button>
        </div>
        <div class="social-channels">
          <h5>{{ footerConfig.social_headline }}</h5>
          <ul>
            <li v-for="(item, key) in footerConfig.social_icons" :key="key">
              <a :href="item.social_link.url" target="_blank"
                ><img :src="item.social_icon.url" :alt="item.social_icon.alt"
              /></a>
            </li>
          </ul>
        </div>
        <div class="claim">
          <span>{{ $root.translate("nunatak") }}</span>
          <p>{{ $root.translate("nunatak_explaination") }}</p>
        </div>
      </div>
      <div class="footer-right">
        <div class="locations">
          <h5>{{ footerConfig.location_headline }}</h5>
          <ul>
            <li v-for="(item, key) in footerConfig.locations" :key="key">
              <a
                :href="'https://www.google.com/maps/search/' + item.address"
                target="_blank"
                v-html="nl2br(item.address)"
              ></a>
            </li>
          </ul>
        </div>
        <div class="certificates">
          <div class="slider" ref="slider">
            <span class="nextArea" @click="$event => next()" :class="{ disabled: currentPage === maxPages }"></span>
            <span class="prevArea" @click="$event => prev()" :class="{ disabled: currentPage === 0 }"></span>
            <ul :style="{ transform: `translate(${((perPage === 1 ? -80 : -100) * currentPage)}%)` }">
              <li v-for="(item, key) in footerConfig.footer_logos" :key="key" >
                <a
                  v-if="item.link"
                  v-bind:href="item.link"
                  target="_blank"
                >
                  <img :src="item.logo.url" :alt="item.logo.alt" />
                </a>
                <span v-else>
                  <img :src="item.logo.url" :alt="item.logo.alt" />
                </span>
              </li>
            </ul>
            <div class="position" v-if="amount > perPage" @click="next()">
              <div
                class="position__indicator"
                :style="positionIndicator()"
              ></div>
            </div>
          </div>

          <!--
          <a href="https://top-consultant.de" target="_blank">
            <img src="./../assets/images/top-consultant.jpg" alt="Top Consultant"/>
          </a>
          <img src="./../assets/images/wirtschafts-woche.jpeg" alt="Certificate Wirtschafts Woche">
          <img src="./../assets/images/focus.png" alt="Certificate Focus">
          <img src="./../assets/images/brandeins.png" alt="Brandeins">
          <a v-if="$route.path.indexOf('/en') === 0" href="https://www.kununu.com/de/the-nunatak-group1">
            <img src="./../assets/images/kununu-top-company-en.png" alt="kununu top company"/>
            <img src="./../assets/images/kununu-open-company-en.png" alt="kununu open company "/>
          </a>
          <a v-if="$route.path.indexOf('/en') !== 0" href="https://www.kununu.com/de/the-nunatak-group1">
            <img src="./../assets/images/kununu-top-company-de.png" alt="kununu top company"/>
            <img src="./../assets/images/kununu-open-company-de.png" alt="kununu open company "/>
          </a>
          <a href="https://www.bvdw.org" target="_blank">
            <img src="./../assets/images/bvdw.png" alt="bvdw"/>
          </a>
          -->
        </div>
        <div class="claim mobile">
          <span>{{ $root.translate("nunatak") }}</span>
          <p>{{ $root.translate("nunatak_explaination") }}</p>
        </div>
      </div>
    </div>
    <div class="wrapper bottom-line">
      <span class="copyright">© 2025 The Nunatak Group</span>
      <nav class="meta">
        <ul>
          <li v-for="(item, key) in menus.meta" :key="key">
            <router-link
              :to="
                ($route.path.indexOf('/en') === 0 ? '/en' : '') +
                '/meta/' +
                item.url.split('/')[item.url.split('/').length - 2]
              "
              >{{ item.title }}</router-link
            >
          </li>
          <li>
            <a href="" @click.prevent="cookieOpen()">{{
              $root.translate("cookie_settings")
            }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import { emailMixin } from "../mixins/emailMixin";
import APIService from "../helpers/APIService";
import PrimaryButton from "./elements/PrimaryButton.vue";

export default {
  name: "ThemeFooter",
  components: {
    PrimaryButton,
  },
  computed: mapState({
    menus: (state) => state.menus.menu,
    language: (state) => state.language.language,
    amount() {
      return this.footerConfig.footer_logos.length;
    },
    maxPages() {
      return Math.ceil(this.amount / this.perPage);
    },
  }),
  data() {
    return {
      contactEmail: "info@nunatak.com",
      footerConfig: null,
      perPage: 4,
      currentPage: 0,
      interval: null,
      show: false
    };
  },
  mixins: [emailMixin],
  watch: {
    language() {
      this.$store.dispatch("menus/getMenu", {
        slug: this.language == "de" ? "17" : "27",
        name: "meta",
      });
      this.$store.dispatch("menus/getMenu", {
        slug: "18",
        name: "social",
      });
    },
    '$route'() {
      this.show = false;
      setTimeout(() => this.show = true, 2000);
    }
  },
  mounted() {
    const api = new APIService();
    api.getOptions().then((response) => {
      if (
        response &&
        response.data &&
        response.data.acf &&
        response.data.acf.footer_logos
      ) {
        this.footerConfig = response.data.acf;
      }
    });

    this.interval = setInterval(() => {
      this.next();
    }, 5000);
  },
  methods: {
    cookieOpen() {
      window.dispatchEvent(new CustomEvent('cookie.open', { detail: true }));
    },
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      const breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    },
    positionIndicator() {
      return {
        width: 100 / this.maxPages + "%",
        left: (100 / this.maxPages) * this.currentPage + "%",
      };
    },
    pageOfActive() {
      if (this.data && this.data.withoutCurrent) return 0;

      let theIndex = 0;
      this.publications.forEach((publication, index) => {
        if (publication.id === this.post.id) {
          theIndex = index;
        }
      });

      return Math.floor((theIndex * this.maxPages) / this.amount);
    },
    next() {
      if (this.currentPage + 1/4 <= this.amount/4 -1) {
        this.currentPage += 1/4;
      } else {
        this.currentPage = 0;
      }
    },
    prev() {
      if (this.currentPage - 1/4 >= 0) {
        this.currentPage -= 1/4;
      } else {
        this.currentPage = this.maxPages - 1;
      }
    },
    openMail(link) {
      window.open(link, '_blank');
    }
  },
};
</script>

<style lang="scss">
@import "../styles/config";
footer {
  .dark & {
    display: none;
  }
  @media screen and (max-width: $medium) {
    padding-bottom: 50px;
  }
  .wrapper {
    display: flex;
    @media screen and (max-width: $medium) {
      display: block;
      margin: 0 0 50px;
    }
    > * {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 0 50px;
      @media screen and (max-width: $medium) {
        width: 100%;
        margin: 0;
      }
      .contact {
        margin: 0 0 50px;
      }
      .claim {
        max-width: 460px;
        font-size: 17px;
        line-height: 32px;
        @media screen and (max-width: $small) {
          font-size: 15px;
          line-height: 24px;
        }
        &.mobile {
          margin: 60px 0 0;
          display: none;
        }
        @media screen and (max-width: $small) {
          display: none;
          &.mobile {
            display: block;
          }
        }
        span {
          font-size: 30px;
          white-space: nowrap;
          margin: 0 0 0.8em;
          display: inline-block;
          @media screen and (max-width: $small) {
            font-size: 27px;
          }
        }
        p {
          margin: 0;
          font-size: 17px;
          line-height: 32px;
          @media screen and (max-width: $small) {
            font-size: 15px;
            line-height: 24px;
          }
        }
        svg {
          fill: $color;
        }
      }
      .social-channels {
        margin: 0 0 50px;
        h5 {
          margin: 0 0 20px;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          font-size: 17px;
          line-height: 32px;
          width: 80%;
          @media screen and (max-width: $small) {
            width: auto;
            font-size: 15px;
            line-height: 24px;
          }
          li {
            margin: 0 20px 0 0;
            @media screen and (max-width: $small) {
              margin: 0 10px 0 0;
            }
            a {
              color: $color;
              border-radius: 50%;
              transition: background-color 200ms ease;
              &:hover {
                background: $primary;
                img {
                  border-color: $primary;
                }
              }
            }
            img {
              display: block;
              width: 38px;
              height: 38px;
              border: 1px solid $color;
              border-radius: 50%;
              transition: border-color 200ms ease;
            }
          }
        }
      }
        
       
        
      .locations {
        margin: 0 0 50px;
        ul {
          display: flex;
          list-style: none;
          flex-wrap: wrap;
          margin: 0;
          padding: 0;
          
          li {
            flex: 1;
            min-width: 33.333%;
            max-height: 33.333%;
            margin: 0 0 20px;
            &:hover {
              color: $color;
            }
          }
          @media screen and (max-width: $medium) {
            display: block;
            li {
              margin: 0 0 20px;
            }
          }
        }
      }
      .certificates {
        display: flex;
        justify-content: center;
      }
      .slider {
        position: relative;
        overflow: hidden;
        width: 99.99%;
        padding-bottom: 20px;
        max-width: 550px;
        span.prevArea,
        span.nextArea {
          position: absolute; 
          right: 0;
          width: 20%;
          background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
          height: 100%;
          z-index: 10;
          opacity: 0;
          transition: opacity 200ms ease;
          cursor: pointer;
          &.disabled {
            opacity: 0 !important;
          }
        }
        span.prevArea {
          right: auto;
          left: 0;
          background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        }
        &:hover {
          span.prevArea,
          span.nextArea {
            opacity: 1;
          }
        }
        ul {
          display: flex;
          margin: 0;
          padding: 0;
          list-style: none;
          transition: transform 500ms ease;
          align-items: center;

          li {
            margin: 0 10px;
            //flex: 1;
            width: calc(25% - 20px);
            min-width: calc(25% - 20px);
            max-height: 150px;
            transition: scale 200ms ease;
            span {
              display: block;
              width: 100%;
              height: 100%;
              max-height: 150px;
            }
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            max-height: 150px;
            object-fit: contain;
          }
        }
      }

      .position {
        position: absolute;
        top: 100%;
        height: 3px;
        width: 180px;
        left: 50%;
        background: rgba($color, 0.3);
        transform: translate(-50%, -3px);
        .position__indicator {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 10%;
          background: $color;
          transition: left 300ms ease;
        }
      }

      .arrows {
        .prev,
        .next {
          position: absolute;
          left: 100%;
          top: calc(50% - 30px);
          transform: translate(0, -50%);
          width: 24px;
        }
        .prev {
          right: 100%;
          left: auto;
          transform: translate(0, -50%) rotate(-180deg);
        }
      }
      span {
        font-size: 17px;
        line-height: 32px;
        @media screen and (max-width: $small) {
          font-size: 15px;
          line-height: 24px;
        }
      }
      a {
        color: $color;
        text-decoration: none;
        display: block;
        transition: color 500ms ease;
        font-size: 17px;
        line-height: 32px;
        @media screen and (max-width: $small) {
          font-size: 15px;
          line-height: 24px;
        }
        &.tel {
          font-size: 40px;
          line-height: 60px;
          font-weight: 300;
          margin-bottom: 0.5em;
          @media screen and (max-width: $small) {
            font-size: 25px;
            line-height: 30px;
          }
        }
        &:hover {
          color: $primary;
        }
      }
    }
    &.bottom-line {
      border-top: 3px solid $color;
      padding: 20px 0;
      @media screen and (max-width: $large) {
        margin: 0 20px;
      }
      @media screen and (max-width: $small) {
        display: flex;
        margin: 0 20px;
        font-size: 11px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        font-size: 15px;
        width: 80%;
        font-weight: 300;
        @media screen and (max-width: $small) {
          width: auto;
          justify-content: flex-end;
        }
        li {
          margin: 0 20px 0 0;
          @media screen and (max-width: $small) {
            margin: 0 10px 0 0;
            a {
              font-size: 11px;
              line-height: 16px;
            }
          }
        }
      }
      .meta ul {
        width: 100%;
        justify-content: flex-end;
        li {
          margin: 0 0 0 20px;
        }
      }
    }
  }
}
</style>
